// WARNING - THIS FILE HAS LARGE AMOUNT OF DUPLICATED CODE !!!
import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CardMedia from '@material-ui/core/CardMedia';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import TextsmsIcon from '@material-ui/icons/Textsms';
import history from './../../utils/history';
import clsx from 'clsx';
import { setPromoProductForCategoryModalOpened } from './../../cart/actions/cart.actions';
import store from '../../store';
import { merge } from 'lodash';
import Lightbox from 'react-image-lightbox';

import { DEFAULT_PRODUCT_IMAGE } from '../../constants';
// import ProductImage from './ProductImage'
import Utils from '../../utils/Utils'
import { customStyles, designV1_1, designV1_2, designV2_1, designV2_2, designV3_1, designV3_2, designV3_3 } from '../styles/styles';

import { setProductPageId } from '../actions/products.action'
import { showHeaderCategory } from '../../categories/actions/categories.action';
import { setScrollData, setAlertMessage } from './../../menu/menu.actions';
import Auth from "../../utils/Auth";

let state = store.getState();

const product_theme = state.client && state.client.product_theme ? (state.client.product_theme) : null;
const mainDesign = product_theme && product_theme.designTheme || 3; //window.localStorage.getItem('design');
const secondaryDesign = product_theme && product_theme.designSubTheme || 3;// window.localStorage.getItem('design');

const designVersion = mainDesign ? parseInt(mainDesign) : 3;
const designSubVersion = secondaryDesign ? parseInt(secondaryDesign) : 3;

let gridXs = 12;
let gridSm = 6;
let gridMd = 4;
let gridLg = 3;
// ------------------------------------------- Default Definitions ^

// Load styles according to selected theme
let cssStyles = designV1_1;
switch (designVersion) {
    case 1:
        if (designSubVersion === 1) {
            cssStyles = designV1_1;
            break;
        } else {
            cssStyles = designV1_2;
            break;
        }
    case 2:
        gridXs = 6; gridSm = 4; gridMd = 3; gridLg = 2;
        if (designSubVersion === 1) {
            cssStyles = designV2_1;
            break;
        } else {
            cssStyles = designV2_2;
            break;
        }
    case 3:
        // cssStyles = this.components['designV3_'+designSubVersion]
        if (designSubVersion === 1) {
            cssStyles = designV3_1;
        } else if (designSubVersion === 2) {
            cssStyles = designV3_2;
        } else {
            cssStyles = designV3_3;
        }
        break;
    default:
        cssStyles = designV3_3;
        break;

}
const styles = theme => (merge(cssStyles, customStyles));

class ProductListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alergensExpanded: false,
            photoIndex: 0,
            photoOpened: false,
        }
    }

    // addToCartHandler = (prodId, prodName) => {
    //     const product_variants = this.props.product.product_variants;
    //     if (product_variants.length === 1) {
    //         this.props.addToCart(prodId, prodName);
    //         // 
    //     } else {
    //         // Open modal
    //     }

    // }

    openProductPage = (prod) => {
        // console.log(prod)
        // Utils.navigateTo('product/' + prod.id);

        if (prod.type === 'booking') {
            return;
        }

        const { translations } = this.props;
        this.props.setProductPageId(prod.id);
        if (this.props.scrollToTopAfterClick) {
            window.scrollTo(0, 0);
        }

        // If product in promotion
        this.props.setPromoProductForCategoryModalOpened(false);

        // console.log('prod', prod)
        // console.log('history', history)
        // history.push()
        // this.props.history.push("/" + this.props.placeSlug + "/"  + cat.slug);

        if (document.getElementById('page-container')) {
            document.getElementById('page-container').scrollTo({ left: 0, top: 0 });
        }

        
        if (this.props.viewProductPage) {
            const currentWholePath = history.location.pathname;
            const currentPathWithoutProduct = currentWholePath.substring(0, currentWholePath.lastIndexOf("/") + 1);
            history.push(currentPathWithoutProduct + prod.slug);
        } else {
            let container = document.querySelector('[data-class="swipeable-container"]');
            if (container) {
                let scrollData = {
                    [history.location.pathname]: container.scrollTop
                };
                this.props.setScrollData(scrollData);
            }

            // If not category selected
            if ((!this.props.catSlug || this.props.catSlug.length == 0) && this.props.category && this.props.category.slug) {
                history.push(history.location.pathname.replace('/search', '') + (history.location.pathname.charAt(history.location.pathname.length - 1) == '/' ? '' : '/') + this.props.category.slug + '/' + prod.slug);
            } else {
                history.push(history.location.pathname + '/' + prod.slug);
            }
        }

        // window.location.hash = prod.slug;
        this.props.showHeaderCategory(translations && translations.translations && translations.translations.common ? translations.translations.common.action.back : '');
        // removeProductPageId

        // // Close search
        // if (this.props.searchOpened) {
        //     this.props.setSearchOpened(false);
        // }
    }

    // productIsInInfoCat = (pId) => {
    //     let pCats = this.props.product['catHierarchyIds'];
    //     let infoCat = false;

    //     Object.values(pCats).map(pCatId => {
    //         if (this.props.categories_hierarchy && this.props.categories_hierarchy[pCatId] && this.props.categories_hierarchy[pCatId].type && this.props.categories_hierarchy[pCatId].type == 'info') {
    //             return true;
    //         }
    //     })

    //     return infoCat;
    // }

    render() {
        const singleProductStyles = this.props.singleProductStyles || false;
        const { classes, product, addToCartBtnClicked, settings, translations, showSubCatInProductHeader, setCommentModalProps, categories_hierarchy, category } = this.props;
        let { id, name, product_variants, product_images, description, allergens, subcategory, in_stock, label, hide_for_ordering, isLiquid, discount_percent, discount_money, hide_variants } = this.props.product;
        const { photoIndex, photoOpened } = this.state;
        const hideProductImage = (settings && settings.hide_product_images_without_img && settings.hide_product_images_without_img.value ? parseInt(settings.hide_product_images_without_img.value) : 0);
        const ClickableVariantsOnlyIfProdWithoutImg = (settings && settings.clickable_product_variants_only_for_products_without_image && settings.clickable_product_variants_only_for_products_without_image.value ? parseInt(settings.clickable_product_variants_only_for_products_without_image.value) : 0);

        name = this.props.product.translations[this.props.currLang] && this.props.product.translations[this.props.currLang].name || this.props.product.name;
        description = this.props.product.translations[this.props.currLang] && this.props.product.translations[this.props.currLang].description || this.props.product.description;

        let prodImgPath = '';
        if (typeof product_images[0] !== "undefined" && typeof product_images[0].path !== "undefined" && product_images[0].path) {
            prodImgPath = Utils.getProductImagePath(product_images[0].path);
        }

        let prodThumbImgPath = '';
        if (typeof product_images[0] !== "undefined" && typeof product_images[0].path !== "undefined" && product_images[0].path) {
            prodThumbImgPath = Utils.getProductThumbImagePath(product_images[0].path);
        }
        if (prodThumbImgPath && prodThumbImgPath.includes("gif")) {
            prodThumbImgPath = prodImgPath;
        }

        const images = [prodImgPath];
        let alergensInfo;
        let alergensButton;

        if (hide_for_ordering == true && (Utils.getSettingsValue(settings, 'hide_products_for_ordering_with_token') == 1) && (Utils.getUserToken() != '')) {
            return null;
        }

        let currentProductCats = [];
        if (product && product.catHierarchyIds) {
            currentProductCats = product.catHierarchyIds;
        }
        let orderAllowed = Utils.checkProductAllowedOrdering(currentProductCats, categories_hierarchy);

        if (Object.values(allergens).length > 0) {
            alergensInfo =
                Object.values(allergens).map(al => (
                    <ListItem button className={classes.alergensListItem}>
                        <img src={al.image} className={classes.alergenIcon} />
                        <ListItemText primary={al.translations[this.props.currLang] && al.translations[this.props.currLang].name || al.name} />
                    </ListItem>
                ))

            alergensButton =
                // (designVersion !== 1) ?
                <IconButton aria-label="info" onClick={() => { this.setState({ alergensExpanded: !this.state.alergensExpanded }) }} style={{
                    color: Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? 'white' : '#1a1a1a',
                    float: 'right'
                }}>
                    <InfoIcon />
                    {/* <SpellcheckIcon/> */}
                </IconButton>
            // :
            // null
        }

        const addButton =
            (orderAllowed && ((settings.clickable_product_variants && settings.clickable_product_variants.value == 0) && Utils.getSettingsValue(settings, 'view_product_page') == 0 || Utils.getSettingsValue(settings, 'view_product_page') == 2)) ?
                <Button
                    variant="text"
                    className={classes.addToCartButton}
                    endIcon={<AddIcon className={classes.addToCartButtonIcon} />}
                    onClick={() => addToCartBtnClicked(id)}
                >
                    {designVersion === 3 && designSubVersion === 3 ?
                        null
                        :
                        // 'Добави'
                        translations.translations && translations.translations.common && translations.translations.common.add_btn || 'Добави'

                        // (settings.order_type && settings.order_type.value && settings.order_type.value == 1 ? 'Добави' : 'Поръчай')
                    }
                </Button>
                :
                null
            ;

        const descriptionText = <Typography component="h3" variant="subtitle1" color="textSecondary" className={classes.productDescription}
            dangerouslySetInnerHTML={{
                __html: name !== description ? description : ''
            }}></Typography>


        let timeOutAnimation = Utils.calculateAnimationTimeout(this.props.k);
        // console.log('this.props.products.all_products[id]', this.props.products.all_products[id])

        // tempk++;

        const infoProductImage = <>
            <div>
                <CardMedia
                    // className={(prodThumbImgPath || (Utils.getSettingsValue(settings, 'view_product_page') != 0)) ? classes.cover : classes.coverContain}
                    // className={classes.coverContain}
                    className={clsx(classes.cover, classes.coverInfoProduct, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 || Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.darkerProductImageCover : {})}
                    image={prodImgPath ? prodImgPath : (prodThumbImgPath ? prodThumbImgPath : DEFAULT_PRODUCT_IMAGE)}
                    title={name}
                    style={{
                        backgroundSize: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'contain' : (prodThumbImgPath ? 'cover' : 'auto')),
                        backgroundColor: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'white' : 'initial'),
                        'transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* W3C */
                        '-webkit-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Safari & Chrome */
                        '-moz-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Firefox */
                        '-ms-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Internet Explorer */
                        '-o-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Opera */
                    }}
                    onClick={() => (prodImgPath ? this.setState({ photoOpened: true }) : void (0))}
                    data-class="each-product-image"
                />

                {photoOpened ?
                    <Lightbox
                        imageTitle={<span
                            dangerouslySetInnerHTML={{
                                __html: name
                            }}
                        ></span>}
                        imageCaption={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: description
                                }}
                            ></span>
                        }
                        mainSrc={images[photoIndex]}
                        onCloseRequest={() => this.setState({ photoOpened: false })}
                    />
                    :
                    null
                }

            </div>
        </>

        const infoProductText = <div style={{
            padding: 10,
            borderLeft: '10px solid #ccc',
            alignSelf: 'center',
            marginLeft: 10
        }}>
            {/* Name container */}
            <Typography component="h2" variant="h5" className={clsx(classes.productName, classes.productNameInfoProduct, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductName : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.darkerProductNameTwo : {}))} style={{ fontWeight: 'bold', paddingTop: ((((showSubCatInProductHeader == 1 || in_stock == false || (label && label.length > 0)) && hideProductImage && !prodThumbImgPath)) ? 35 : 0) }}>
                <span className={(Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : ''} data-class="product-info-name"
                    dangerouslySetInnerHTML={{
                        __html: name
                    }}
                ></span>
            </Typography>

            {/* Description container */}
            {
                ((designVersion === 1 && designSubVersion === 2) || (designVersion === 2) || (designVersion === 3)) && description.length > 0 ?
                    <span data-class="product-info-description" >{descriptionText}</span>
                    :
                    null
            }
        </div>

        var spotBrowseOnly = false;
        if (Auth.getTokenOrderType() || this.props.chosenPlaceOrderTypes.length == 1) {
            if (Auth.getTokenOrderType()) {
                var type = this.props.chosenPlaceOrderTypes.find(ot => ot.id === Auth.getTokenOrderType())

                if (type && type.type) {
                    type = type.type;
                }
                // FIXED? Potential bug - once it crashed because of .type not found
                // console.log('type', type);

                if (type == "spot_browse" || type == 'tablet_mode') {
                    spotBrowseOnly = true;
                }
            } else {
                if (this.props.chosenPlaceOrderTypes.length == 1) {
                    if (this.props.chosenPlaceOrderTypes && this.props.chosenPlaceOrderTypes[0] && this.props.chosenPlaceOrderTypes[0].type && (this.props.chosenPlaceOrderTypes[0].type == 'spot_browse' || this.props.chosenPlaceOrderTypes[0].type == 'tablet_mode')) {
                        spotBrowseOnly = true;
                    }
                }
            }
        }

        // WARNING - THIS FILE HAS LARGE AMOUNT OF DUPLICATED CODE !!!
        return (
            <>
                {this.props.preventGrow ?
                    <>
                        {category && category.type == 'info' ?
                            <Grid container style={{
                                background: '#EAEEF3',
                                // background: 'rgb(255,255,255)',
                                // background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.25) 100%)',
                                marginBottom: 35
                            }}>

                                <Grid item xs={12} sm={this.props.k % 2 == 0 ? 4 : 8} style={{ display: 'grid' }}>
                                    {this.props.k % 2 == 0 ? infoProductImage : infoProductText}
                                </Grid>

                                <Grid item xs={12} sm={this.props.k % 2 == 0 ? 8 : 4} style={{ display: 'grid' }}>
                                    {this.props.k % 2 == 0 ? infoProductText : infoProductImage}
                                </Grid>

                            </Grid>
                            :
                            <Grid item xs={gridXs} sm={singleProductStyles ? 12 : gridSm} md={singleProductStyles ? 12 : gridMd} lg={singleProductStyles ? 12 : gridLg} >

                                <Card className={in_stock == false ? clsx(classes.root, classes.outOfStockCard, (Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductContainer : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.whiterProductContainer : {}))) : clsx(classes.root, (Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductContainer : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.whiterProductContainer : {})))} data-class="product-container">

                                    {showSubCatInProductHeader && showSubCatInProductHeader == 1 ?
                                        <span className={classes.subcategoryName} style={{ fontWeight: 'bold' }}>{subcategory.name}</span>
                                        :
                                        null
                                    }

                                    {label && label.length > 0 && showSubCatInProductHeader != 1 ?
                                        <span className={classes.labelName} data-class="product-label">{label}</span>
                                        :
                                        null
                                    }

                                    {in_stock == false ?
                                        <span className={classes.outOfStockLabel}>{translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.out_of_stock ? translations.translations.pages.products.out_of_stock : ''}</span>
                                        :
                                        null
                                    }

                                    <div className={classes.details}>
                                        <CardContent className={classes.content}>

                                            {/* Allergens and Name container */}
                                            <Typography component="h2" variant="h5" className={clsx(classes.productName, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductName : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.darkerProductNameTwo : {}))} style={{ paddingTop: ((((showSubCatInProductHeader == 1 || in_stock == false || (label && label.length > 0)) && hideProductImage && !prodThumbImgPath)) ? 35 : 0) }}>
                                                {alergensButton} <span onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)} className={(Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : ''} data-class="product-name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: name
                                                    }}
                                                ></span>
                                            </Typography>

                                            {/* Description container */}
                                            {((designVersion === 1 && designSubVersion === 2) || (designVersion === 2) || (designVersion === 3)) && description.length > 0 ?
                                                <span onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)} className={clsx((Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : '', Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductDescription : {})} data-class="product-description" >{descriptionText}</span>
                                                :
                                                null
                                            }

                                            {/* Allergens collapse container */}
                                            <Collapse in={this.state.alergensExpanded} timeout="auto" unmountOnExit className={clsx(classes.alergensCollapseContainer, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductAllergens : {})}>
                                                <div className={classes.alergensCointainer}>
                                                    <div style={{ fontWeight: 'bold', fontSize: 15 }}>{translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.alergens ? translations.translations.pages.products.alergens : ''}</div>
                                                    <List component="nav" aria-label="secondary alergens">
                                                        {alergensInfo}
                                                    </List>
                                                    <Divider />
                                                </div>
                                            </Collapse>

                                            {(designVersion === 3 && designSubVersion === 3) ? addButton : null}

                                        </CardContent>

                                        {/* Variants container */}
                                        <div className={classes.options} data-class="variants-container">
                                            <Grid container direction="row" alignItems="center">
                                                {hide_variants && orderAllowed ?
                                                    <React.Fragment>
                                                        <ListItem button={Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 0} className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} data-class="product-variant-button" style={{ borderBottom: 'none', textTransform: 'uppercase' }} onClick={() => this.openProductPage(this.props.product)}>
                                                            <Grid item xs={12} className={classes.variantColumnWithOrderButtonContainer}>
                                                                {Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 0 ?
                                                                    <div style={{ paddingTop: 7 }}>
                                                                        {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.choose_ingredients ? translations.translations.pages.products.choose_ingredients : ''}
                                                                    </div>
                                                                    :
                                                                    <Button
                                                                        variant="text"
                                                                        className={classes.addToCartVariantOrderButton}
                                                                        // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                        // onClick={() => this.openProductPage(this.props.product)}
                                                                        style={{ display: 'block', margin: '0 auto' }}
                                                                    >
                                                                        {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.choose_ingredients ? translations.translations.pages.products.choose_ingredients : ''}
                                                                    </Button>
                                                                }
                                                            </Grid>
                                                        </ListItem>
                                                    </React.Fragment>
                                                    :
                                                    product_variants.map((variant, k) => {
                                                        let calculatedProductPrice = Utils.calculateProductPriceListingProducts(variant.price, discount_percent, discount_money, settings);

                                                        return (
                                                            <React.Fragment key={k}>
                                                                {Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 1 && orderAllowed ?
                                                                    <ListItem className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} key={k} data-class="product-variant-button" style={{ borderBottom: product_variants.length > 1 && product_variants.length != k + 1 ? '2px solid #eee' : 'none' }} >
                                                                        <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                                            <div>
                                                                                <div>
                                                                                    <b className={classes.variantColumnWithOrderButtonVariantContainer} data-class="price-and-currency-container">
                                                                                        {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                                                            <React.Fragment>
                                                                                                <span className="skiptranslate strikethrough"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        <span className='skiptranslate'>
                                                                                            {calculatedProductPrice.includes('.') ?
                                                                                                <React.Fragment>
                                                                                                    {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                calculatedProductPrice
                                                                                            }
                                                                                        </span>

                                                                                        {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : translations.translations && translations.translations.common && translations.translations.common.currency_bgn || ' лв.'}
                                                                                    </b>
                                                                                </div>
                                                                                <div className={classes.variantColumnWithOrderButtonPriceContainer}>{variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}</div>
                                                                            </div>
                                                                        </Grid>


                                                                        {Utils.getSetting('hide_add_to_cart_btn') == 1 || (Utils.getSetting('hide_add_to_cart_btn') == 2 && spotBrowseOnly == 1) ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                    {Utils.getSetting('change_product_comment_open_action') == 2 ?
                                                                                        <Button
                                                                                            variant="text"
                                                                                            className={classes.addCommentVariantOrderButton}
                                                                                            // style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                            // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                            // onClick={() => setCommentModalProps(name, variant.id)}
                                                                                            onClick={() => this.openProductPage(this.props.product)}
                                                                                        >
                                                                                            {Utils.getSetting('custom_text_for_second_product_variant_button') && Utils.getSetting('custom_text_for_second_product_variant_button').length > 0 ?
                                                                                                Utils.getSetting('custom_text_for_second_product_variant_button') :
                                                                                                (translations.translations && translations.translations.common && translations.translations.common.open ? translations.translations.common.open : '')
                                                                                            }                                                                                        </Button>
                                                                                        :
                                                                                        <Button
                                                                                            variant="text"
                                                                                            className={classes.addCommentVariantOrderButton}
                                                                                            style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                            // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                            onClick={() => setCommentModalProps(name, variant.id)}
                                                                                            data-class="product-variant-comment-button"
                                                                                        >
                                                                                            {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                                                        </Button>
                                                                                    }

                                                                                </Grid>

                                                                                <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                    <Button
                                                                                        variant="text"
                                                                                        className={classes.addToCartVariantOrderButton}
                                                                                        // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                        onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                                    >
                                                                                        {Utils.getSettingsValue(settings, 'add_to_cart_text') || (translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add ? translations.translations.pages.products.add : '')}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                    </ListItem>
                                                                    :
                                                                    Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 2 && orderAllowed ?

                                                                        <ListItem className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} key={k} data-class="product-variant-button" style={{ borderBottom: product_variants.length > 1 && product_variants.length != k + 1 ? '2px solid #eee' : 'none' }} >
                                                                            <Grid container direction="row" alignItems="center">
                                                                                <Grid container direction="row" alignItems="center">

                                                                                    <Grid item xs={12} className={clsx(classes.variantColumnWithOrderButtonContainer, classes.variantColumnWithOrderButtonContainerFirstChild)}>
                                                                                        <div>
                                                                                            <span>{variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}</span>
                                                                                            &nbsp;
                                                                                            {this.props.product.type === 'booking' && variant.booking_duration ?
                                                                                                <span>{variant.booking_duration} {translations.translations && translations.translations.pages.booking.minutes || ''}</span>
                                                                                                : null
                                                                                            }
                                                                                            &nbsp;
                                                                                            <b data-class="price-and-currency-container" >
                                                                                                {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                                                                    <React.Fragment>
                                                                                                        <span className="strikethrough skiptranslate"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                                    </React.Fragment>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                <span className='skiptranslate'>
                                                                                                    {calculatedProductPrice.includes('.') ?
                                                                                                        <React.Fragment>
                                                                                                            {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        calculatedProductPrice
                                                                                                    }
                                                                                                </span>

                                                                                                {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : translations.translations && translations.translations.common && translations.translations.common.currency_bgn || ' лв.'}
                                                                                            </b>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <br />
                                                                                <Grid container direction="row" alignItems="center">

                                                                                    {Auth.isTabletMode() ?
                                                                                        <Grid item xs={12} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                            <Button
                                                                                                variant="text"
                                                                                                className={classes.addToCartVariantOrderButton}
                                                                                                // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                                onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                                            >
                                                                                                {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.save_for_order ? translations.translations.pages.products.save_for_order : ''}
                                                                                            </Button>
                                                                                        </Grid>
                                                                                        :
                                                                                        <React.Fragment data-class="product-variant-buttons-container">
                                                                                            {this.props.product.type != 'booking' && (this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) ?
                                                                                                <Grid item xs={6} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                                    <Button
                                                                                                        variant="text"
                                                                                                        className={classes.addCommentVariantOrderButton}
                                                                                                        style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                                        // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                                        onClick={() => setCommentModalProps(name, variant.id)}
                                                                                                        data-class="product-variant-comment-button"
                                                                                                    >
                                                                                                        {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                                                                    </Button>
                                                                                                </Grid>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            <Grid item xs={this.props.product.type != 'booking' && (this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) ? 6 : 12} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                                <Button
                                                                                                    variant="text"
                                                                                                    className={classes.addToCartVariantOrderButton}
                                                                                                    // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                                    onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                                                >
                                                                                                    {this.props.product && this.props.product.type == 'booking' ?
                                                                                                        translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.book ? translations.translations.pages.products.book : ''
                                                                                                        :
                                                                                                        (Utils.getSettingsValue(settings, 'add_to_cart_text') || (translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add ? translations.translations.pages.products.add : ''))
                                                                                                    }
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        </ListItem>

                                                                        :

                                                                        // orderAllowed ?

                                                                        <ListItem button={Auth.getTokenOrderType() != 4 ? true : false} className={clsx(settings.clickable_product_variants && settings.clickable_product_variants.value == 1 ? classes.variantsContainerClickableVariants : classes.variantsContainer, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} key={k} onClick={() => orderAllowed ? this.props.addProdToCart(id, variant.id, this.props.product) : this.props.setAlertMessage(true, 'Този продукт не може да бъде поръчан')} data-class="product-variant-button" style={{ borderBottom: product_variants.length > 1 && product_variants.length != k + 1 ? '2px solid #eee' : 'none' }} >
                                                                            {((settings.clickable_product_variants && settings.clickable_product_variants.value == 1) || (ClickableVariantsOnlyIfProdWithoutImg && hideProductImage && !prodThumbImgPath)) ?
                                                                                <>
                                                                                    {Auth.getTokenOrderType() != 4 ?
                                                                                        <Grid item xs={1} style={{ marginRight: 10 }}>
                                                                                            <>
                                                                                                {Auth.getTokenOrderType() != 4 ?
                                                                                                    <>
                                                                                                        {Utils.getSettingsValue(settings, 'cart_icon') == 1 ?
                                                                                                            <AddShoppingCartIcon style={{ verticalAlign: 'middle' }} />
                                                                                                            :
                                                                                                            <AddIcon style={{ verticalAlign: 'middle' }} />
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </>
                                                                                        </Grid>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            <Grid item xs={settings.clickable_product_variants && settings.clickable_product_variants.value == 1 ? 6 : 7} >
                                                                                {/* {variant.size} */}
                                                                                {variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}
                                                                            </Grid>
                                                                            <Grid item xs={Auth.getTokenOrderType() != 4 ? 5 : 6} style={{ textAlign: 'right', fontWeight: 'bold' }} data-class="price-and-currency-container" >
                                                                                {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                                                    <React.Fragment>
                                                                                        <span className="strikethrough skiptranslate"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <span className='skiptranslate'>
                                                                                    {calculatedProductPrice.includes('.') ?
                                                                                        <React.Fragment>
                                                                                            {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        calculatedProductPrice
                                                                                    }
                                                                                </span>

                                                                                {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : translations.translations && translations.translations.common && translations.translations.common.currency_bgn || ' лв.'}
                                                                            </Grid>
                                                                        </ListItem>

                                                                    // :
                                                                    // null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }

                                                {(designVersion === 3 && designSubVersion === 3 && hideProductImage != 0) ? addButton : null}
                                                {((designVersion === 1 && designSubVersion === 1) || (designVersion === 2) || (designVersion === 3 && designSubVersion !== 3)) ? addButton : null}

                                            </Grid>

                                            <Grid container direction="row" alignItems="center">
                                                {(Utils.getSettingsValue(settings, 'show_change_ingredients_button') == 1) && !hide_variants &&
                                                    (this.props.products && this.props.products.all_products && this.props.products.all_products[id] && this.props.products.all_products[id].product_ingredients && ((this.props.products.all_products[id].product_ingredients).length > 0)) ?
                                                    <ListItem button className={settings.clickable_product_variants && parseInt(settings.clickable_product_variants.value) == 1 ? classes.variantsContainerClickableVariants : classes.variantsContainer}
                                                        onClick={() => this.openProductPage(this.props.product)}
                                                        data-class="view-product-variant-button" >
                                                        {((settings.clickable_product_variants && parseInt(settings.clickable_product_variants.value) == 1)) ?
                                                            <Grid item xs={12} style={{ marginRight: 10, textAlign: 'center' }}>
                                                                {(translations && translations.translations && translations.translations.common && translations.translations.common.modify_ingredients ? (translations.translations.common.modify_ingredients).toUpperCase() : '')}
                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                    </ListItem>
                                                    :
                                                    null
                                                }
                                            </Grid>

                                        </div>
                                    </div>

                                    {/* <ProductImage
                                        image={prodThumbImgPath}
                                        title={name}
                                    /> */}

                                    {photoOpened && (
                                        <Lightbox
                                            imageTitle={<span
                                                dangerouslySetInnerHTML={{
                                                    __html: name
                                                }}
                                            ></span>}
                                            imageCaption={
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: description
                                                    }}
                                                ></span>
                                            }
                                            mainSrc={images[photoIndex]}
                                            onCloseRequest={() => this.setState({ photoOpened: false })}
                                        />
                                    )}


                                    {hideProductImage && !prodThumbImgPath ?
                                        null
                                        :
                                        <CardMedia
                                            className={clsx((prodThumbImgPath || (Utils.getSettingsValue(settings, 'view_product_page') != 0)) ? classes.cover : classes.coverContain, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 || Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.darkerProductImageCover : {})}
                                            image={prodThumbImgPath ? prodThumbImgPath : DEFAULT_PRODUCT_IMAGE}
                                            title={name}
                                            style={{
                                                backgroundSize: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'contain' : (prodThumbImgPath ? 'cover' : 'auto')),
                                                backgroundColor: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'white' : 'initial'),
                                                'transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* W3C */
                                                '-webkit-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Safari & Chrome */
                                                '-moz-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Firefox */
                                                '-ms-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Internet Explorer */
                                                '-o-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Opera */
                                            }}
                                            onClick={() => {
                                                Utils.getSettingsValue(settings, 'view_product_page') != 0 ?
                                                    this.openProductPage(this.props.product)
                                                    :
                                                    (prodThumbImgPath ? this.setState({ photoOpened: true }) : void (0))
                                            }}
                                            data-class="each-product-image"
                                        />

                                        // onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)}
                                    }

                                </Card>

                                {designVersion === 1 && designSubVersion === 2 ? addButton : null}
                                {designVersion === 1 && designSubVersion === 1 && description.length > 0 ? descriptionText : null}

                            </Grid>
                        }
                    </>

                    :

                    <Grow in={true} timeout={{ enter: timeOutAnimation, exit: timeOutAnimation }} key={this.props.k}>
                        {category && category.type == 'info' ?
                            <Grid container style={{
                                background: '#EAEEF3',
                                // background: 'rgb(255,255,255)',
                                // background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.25) 100%)',
                                marginBottom: 35
                            }}>

                                <Grid item xs={12} sm={this.props.k % 2 == 0 ? 4 : 8} style={{ display: 'grid' }}>
                                    {this.props.k % 2 == 0 ? infoProductImage : infoProductText}
                                </Grid>

                                <Grid item xs={12} sm={this.props.k % 2 == 0 ? 8 : 4} style={{ display: 'grid' }}>
                                    {this.props.k % 2 == 0 ? infoProductText : infoProductImage}
                                </Grid>

                            </Grid>
                            :
                            <Grid item xs={gridXs} sm={singleProductStyles ? 12 : gridSm} md={singleProductStyles ? 12 : gridMd} lg={singleProductStyles ? 12 : gridLg} >

                                <Card className={in_stock == false ? clsx(classes.root, classes.outOfStockCard, (Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductContainer : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.whiterProductContainer : {}))) : clsx(classes.root, (Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductContainer : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.whiterProductContainer : {})))} data-class="product-container">

                                    {showSubCatInProductHeader && showSubCatInProductHeader == 1 ?
                                        <span className={classes.subcategoryName} style={{ fontWeight: 'bold' }}>{subcategory.name}</span>
                                        :
                                        null
                                    }

                                    {label && label.length > 0 && showSubCatInProductHeader != 1 ?
                                        <span className={classes.labelName} data-class="product-label">{label}</span>
                                        :
                                        null
                                    }

                                    {in_stock == false ?
                                        <span className={classes.outOfStockLabel}>{translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.out_of_stock ? translations.translations.pages.products.out_of_stock : ''}</span>
                                        :
                                        null
                                    }

                                    <div className={classes.details}>
                                        <CardContent className={classes.content}>

                                            {/* Allergens and Name container */}
                                            <Typography component="h2" variant="h5" className={clsx(classes.productName, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductName : (Utils.getSettingsValue(settings, 'darker_theme_products') == 2 ? classes.darkerProductNameTwo : {}))} style={{ paddingTop: ((((showSubCatInProductHeader == 1 || in_stock == false || (label && label.length > 0)) && hideProductImage && !prodThumbImgPath)) ? 35 : 0) }}>
                                                {alergensButton} <span onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)} className={(Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : ''} data-class="product-name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: name
                                                    }}
                                                ></span>
                                            </Typography>

                                            {/* Description container */}
                                            {((designVersion === 1 && designSubVersion === 2) || (designVersion === 2) || (designVersion === 3)) && description.length > 0 ?
                                                <span onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)} className={clsx((Utils.getSettingsValue(settings, 'view_product_page') != 0) ? 'cursor-pointer-onhover' : '', Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductDescription : {})} data-class="product-description" >{descriptionText}</span>
                                                :
                                                null
                                            }

                                            {/* Allergens collapse container */}
                                            <Collapse in={this.state.alergensExpanded} timeout="auto" unmountOnExit className={clsx(classes.alergensCollapseContainer, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductAllergens : {})}>
                                                <div className={classes.alergensCointainer}>
                                                    <div style={{ fontWeight: 'bold', fontSize: 15 }}>{translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.alergens ? translations.translations.pages.products.alergens : ''}</div>
                                                    <List component="nav" aria-label="secondary alergens">
                                                        {alergensInfo}
                                                    </List>
                                                    <Divider />
                                                </div>
                                            </Collapse>

                                            {(designVersion === 3 && designSubVersion === 3) ? addButton : null}

                                        </CardContent>

                                        {/* Variants container */}
                                        <div className={classes.options} data-class="variants-container">
                                            <Grid container direction="row" alignItems="center">
                                                {hide_variants && orderAllowed ?
                                                    <React.Fragment>
                                                        <ListItem button={Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 0} className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} data-class="product-variant-button" style={{ borderBottom: 'none', textTransform: 'uppercase' }} onClick={() => this.openProductPage(this.props.product)}>
                                                            <Grid item xs={12} className={classes.variantColumnWithOrderButtonContainer}>
                                                                {Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 0 ?
                                                                    <div style={{ paddingTop: 7 }}>
                                                                        {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.choose_ingredients ? translations.translations.pages.products.choose_ingredients : ''}
                                                                    </div>
                                                                    :
                                                                    <Button
                                                                        variant="text"
                                                                        className={classes.addToCartVariantOrderButton}
                                                                        // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                        // onClick={() => this.openProductPage(this.props.product)}
                                                                        style={{ display: 'block', margin: '0 auto' }}
                                                                    >
                                                                        {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.choose_ingredients ? translations.translations.pages.products.choose_ingredients : ''}
                                                                    </Button>
                                                                }
                                                            </Grid>
                                                        </ListItem>
                                                    </React.Fragment>
                                                    :
                                                    product_variants.map((variant, k) => {
                                                        let calculatedProductPrice = Utils.calculateProductPriceListingProducts(variant.price, discount_percent, discount_money, settings);

                                                        return (
                                                            <React.Fragment key={k}>
                                                                {Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 1 && orderAllowed ?
                                                                    <ListItem className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} key={k} data-class="product-variant-button" style={{ borderBottom: product_variants.length > 1 && product_variants.length != k + 1 ? '2px solid #eee' : 'none' }} >
                                                                        <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                                            <div>
                                                                                <div>
                                                                                    <b className={classes.variantColumnWithOrderButtonVariantContainer} data-class="price-and-currency-container" >
                                                                                        {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                                                            <React.Fragment>
                                                                                                <span className="strikethrough skiptranslate"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        <span className='skiptranslate'>
                                                                                            {calculatedProductPrice.includes('.') ?
                                                                                                <React.Fragment>
                                                                                                    {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                                </React.Fragment>
                                                                                                :
                                                                                                calculatedProductPrice
                                                                                            }
                                                                                        </span>

                                                                                        {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : translations.translations && translations.translations.common && translations.translations.common.currency_bgn || ' лв.'}
                                                                                    </b>
                                                                                </div>
                                                                                <div className={classes.variantColumnWithOrderButtonPriceContainer}>{variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}</div>
                                                                            </div>
                                                                        </Grid>

                                                                        {Utils.getSetting('hide_add_to_cart_btn') == 1 || (Utils.getSetting('hide_add_to_cart_btn') == 2 && spotBrowseOnly == 1) ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                    {/* <Button
                                                                                        variant="text"
                                                                                        className={classes.addCommentVariantOrderButton}
                                                                                        style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                        // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                        onClick={() => setCommentModalProps(name, variant.id)}
                                                                                    >
                                                                                        {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                                                    </Button> */}

                                                                                    {/*  */}
                                                                                    {Utils.getSetting('change_product_comment_open_action') == 2 ?
                                                                                        <Button
                                                                                            variant="text"
                                                                                            className={classes.addCommentVariantOrderButton}
                                                                                            // style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                            // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                            // onClick={() => setCommentModalProps(name, variant.id)}
                                                                                            onClick={() => this.openProductPage(this.props.product)}
                                                                                        >
                                                                                            {Utils.getSetting('custom_text_for_second_product_variant_button') && Utils.getSetting('custom_text_for_second_product_variant_button').length > 0 ?
                                                                                                Utils.getSetting('custom_text_for_second_product_variant_button') :
                                                                                                (translations.translations && translations.translations.common && translations.translations.common.open ? translations.translations.common.open : '')
                                                                                            }
                                                                                        </Button>
                                                                                        :
                                                                                        <Button
                                                                                            variant="text"
                                                                                            className={classes.addCommentVariantOrderButton}
                                                                                            style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                            // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                            onClick={() => setCommentModalProps(name, variant.id)}
                                                                                            data-class="product-variant-comment-button"
                                                                                        >
                                                                                            {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                                                        </Button>
                                                                                    }
                                                                                    {/*  */}

                                                                                </Grid>

                                                                                <Grid item xs={4} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                    <Button
                                                                                        variant="text"
                                                                                        className={classes.addToCartVariantOrderButton}
                                                                                        // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                        onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                                    >
                                                                                        {Utils.getSettingsValue(settings, 'add_to_cart_text') || (translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add ? translations.translations.pages.products.add : '')}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </>
                                                                        }
                                                                    </ListItem>
                                                                    :
                                                                    Utils.getSettingsValue(settings, 'order_button_on_product_variant') == 2 && orderAllowed ?

                                                                        <ListItem className={clsx(classes.variantsContainer, classes.variantsContainerMultipleButtons, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} key={k} data-class="product-variant-button" style={{ borderBottom: product_variants.length > 1 && product_variants.length != k + 1 ? '2px solid #eee' : 'none' }} >
                                                                            <Grid container direction="row" alignItems="center">
                                                                                <Grid container direction="row" alignItems="center">

                                                                                    <Grid item xs={12} className={clsx(classes.variantColumnWithOrderButtonContainer, classes.variantColumnWithOrderButtonContainerFirstChild)}>
                                                                                        <div>
                                                                                            <span>{variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}</span>
                                                                                            &nbsp;
                                                                                            {this.props.product.type === 'booking' && variant.booking_duration ?
                                                                                                <span>{variant.booking_duration} {translations.translations && translations.translations.pages.booking.minutes || ''}</span>
                                                                                                : null
                                                                                            }
                                                                                            &nbsp;
                                                                                            <b data-class="price-and-currency-container" >
                                                                                                {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                                                                    <React.Fragment>
                                                                                                        <span className="strikethrough skiptranslate"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                                    </React.Fragment>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                <span className='skiptranslate'>
                                                                                                    {calculatedProductPrice.includes('.') ?
                                                                                                        <React.Fragment>
                                                                                                            {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                                        </React.Fragment>
                                                                                                        :
                                                                                                        calculatedProductPrice
                                                                                                    }
                                                                                                </span>

                                                                                                {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : translations.translations && translations.translations.common && translations.translations.common.currency_bgn || ' лв.'}
                                                                                            </b>
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <br />
                                                                                {Utils.getSetting('hide_add_to_cart_btn') == 1 || (Utils.getSetting('hide_add_to_cart_btn') == 2 && spotBrowseOnly == 1) ?
                                                                                    null
                                                                                    :
                                                                                    <Grid container direction="row" alignItems="center">
                                                                                        {Auth.isTabletMode() ?
                                                                                            <Grid item xs={12} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                                <Button
                                                                                                    variant="text"
                                                                                                    className={classes.addToCartVariantOrderButton}
                                                                                                    // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                                    onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                                                >
                                                                                                    {translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.save_for_order ? translations.translations.pages.products.save_for_order : ''}
                                                                                                </Button>
                                                                                            </Grid>
                                                                                            :
                                                                                            <React.Fragment data-class="product-variant-buttons-container">
                                                                                                {/* <div data-class="product-variant-buttons-container"> */}
                                                                                                {this.props.product.type != 'booking' && (this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) ?
                                                                                                    <Grid item xs={6} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                                        <Button
                                                                                                            variant="text"
                                                                                                            className={classes.addCommentVariantOrderButton}
                                                                                                            style={{ color: this.props.products.order_comment.findIndex(p => p.variantId === variant.id && p.comment.length > 0) !== -1 ? '#d95900' : 'initial' }}
                                                                                                            // endIcon={<TextsmsIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                                            onClick={() => setCommentModalProps(name, variant.id)}
                                                                                                            data-class="product-variant-comment-button"
                                                                                                        >
                                                                                                            {translations.translations && translations.translations.common && translations.translations.common.comment ? translations.translations.common.comment : ''}
                                                                                                        </Button>
                                                                                                    </Grid>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                <Grid item xs={this.props.product.type != 'booking' && (this.props.chosenPlaceOrderTypes.findIndex(p => p.type == "spot") > -1) ? 6 : 12} className={classes.variantColumnWithOrderButtonContainer}>
                                                                                                    <Button
                                                                                                        variant="text"
                                                                                                        className={classes.addToCartVariantOrderButton}
                                                                                                        // endIcon={<AddIcon className={classes.addToCartVariantButtonIcon} />}
                                                                                                        onClick={() => addToCartBtnClicked(id, variant.id)}
                                                                                                    >
                                                                                                        {this.props.product && this.props.product.type == 'booking' ?
                                                                                                            translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.book ? translations.translations.pages.products.book : ''
                                                                                                            :
                                                                                                            (Utils.getSettingsValue(settings, 'add_to_cart_text') || (translations.translations && translations.translations.pages && translations.translations.pages.products && translations.translations.pages.products.add ? translations.translations.pages.products.add : ''))
                                                                                                        }
                                                                                                    </Button>
                                                                                                </Grid>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </ListItem>

                                                                        :

                                                                        // orderAllowed ?

                                                                        <ListItem button={Auth.getTokenOrderType() != 4 ? true : false} className={clsx(settings.clickable_product_variants && settings.clickable_product_variants.value == 1 ? classes.variantsContainerClickableVariants : classes.variantsContainer, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductVariants : {})} key={k} onClick={() => orderAllowed ? this.props.addProdToCart(id, variant.id, this.props.product) : this.props.setAlertMessage(true, 'Този продукт не може да бъде поръчан')} data-class="product-variant-button" style={{ borderBottom: product_variants.length > 1 && product_variants.length != k + 1 ? '2px solid #eee' : 'none' }} >
                                                                            {((settings.clickable_product_variants && settings.clickable_product_variants.value == 1) || (ClickableVariantsOnlyIfProdWithoutImg && hideProductImage && !prodThumbImgPath)) ?
                                                                                <>
                                                                                    {Auth.getTokenOrderType() != 4 ?
                                                                                        <Grid item xs={1} style={{ marginRight: 10 }}>
                                                                                            <>
                                                                                                {Auth.getTokenOrderType() != 4 ?
                                                                                                    <>
                                                                                                        {Utils.getSettingsValue(settings, 'cart_icon') == 1 ?
                                                                                                            <AddShoppingCartIcon style={{ verticalAlign: 'middle' }} />
                                                                                                            :
                                                                                                            <AddIcon style={{ verticalAlign: 'middle' }} />
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </>
                                                                                        </Grid>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                            }
                                                                            <Grid item xs={settings.clickable_product_variants && settings.clickable_product_variants.value == 1 ? 6 : 7} >
                                                                                {/* {variant.size} */}
                                                                                {variant.translations[this.props.currLang] && variant.translations[this.props.currLang].size || variant.size}
                                                                            </Grid>
                                                                            <Grid item xs={Auth.getTokenOrderType() != 4 ? 5 : 6} style={{ textAlign: 'right', fontWeight: 'bold' }} data-class="price-and-currency-container" >
                                                                                {(discount_percent && parseFloat(discount_percent) != 0) || (discount_money && parseFloat(discount_money) != 0 & Utils.getSettingsValue(settings, 'calculate_product_discount_money_only_for_preorder_orders') != 1) ?
                                                                                    <React.Fragment>
                                                                                        <span className="strikethrough skiptranslate"><span className={classes.oldPrice}>{variant.price}</span></span>&nbsp;
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <span className='skiptranslate'>
                                                                                    {calculatedProductPrice.includes('.') ?
                                                                                        <React.Fragment>
                                                                                            {calculatedProductPrice.split('.')[0]}<span data-class="product-variant-price-dot">.</span><span data-class="product-variant-price-cents">{calculatedProductPrice.split('.')[1]}</span>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        calculatedProductPrice
                                                                                    }
                                                                                </span>

                                                                                {settings.default_currency_suffix && settings.default_currency_suffix.value ? ' ' + settings.default_currency_suffix.value : translations.translations && translations.translations.common && translations.translations.common.currency_bgn || ' лв.'}
                                                                            </Grid>
                                                                        </ListItem>

                                                                    // :
                                                                    // null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }

                                                {(designVersion === 3 && designSubVersion === 3 && hideProductImage != 0) ? addButton : null}
                                                {((designVersion === 1 && designSubVersion === 1) || (designVersion === 2) || (designVersion === 3 && designSubVersion !== 3)) ? addButton : null}

                                            </Grid>

                                            <Grid container direction="row" alignItems="center">
                                                {(Utils.getSettingsValue(settings, 'show_change_ingredients_button') == 1) && !hide_variants &&
                                                    (this.props.products && this.props.products.all_products && this.props.products.all_products[id] && this.props.products.all_products[id].product_ingredients && ((this.props.products.all_products[id].product_ingredients).length > 0)) ?
                                                    <ListItem button className={settings.clickable_product_variants && parseInt(settings.clickable_product_variants.value) == 1 ? classes.variantsContainerClickableVariants : classes.variantsContainer}
                                                        onClick={() => this.openProductPage(this.props.product)}
                                                        data-class="view-product-variant-button" >
                                                        {((settings.clickable_product_variants && parseInt(settings.clickable_product_variants.value) == 1)) ?
                                                            <Grid item xs={12} style={{ marginRight: 10, textAlign: 'center' }}>
                                                                {(translations && translations.translations && translations.translations.common && translations.translations.common.modify_ingredients ? (translations.translations.common.modify_ingredients).toUpperCase() : '')}
                                                            </Grid>
                                                            :
                                                            null
                                                        }
                                                    </ListItem>
                                                    :
                                                    null
                                                }
                                            </Grid>

                                        </div>
                                    </div>

                                    {/* <ProductImage
                                        image={prodThumbImgPath}
                                        title={name}
                                    /> */}

                                    {photoOpened && (
                                        <Lightbox
                                            imageTitle={<span
                                                dangerouslySetInnerHTML={{
                                                    __html: name
                                                }}
                                            ></span>}
                                            imageCaption={
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: description
                                                    }}
                                                ></span>
                                            }
                                            mainSrc={images[photoIndex]}
                                            onCloseRequest={() => this.setState({ photoOpened: false })}
                                        />
                                    )}


                                    {hideProductImage && !prodThumbImgPath ?
                                        null
                                        :
                                        <CardMedia
                                            className={clsx((prodThumbImgPath || (Utils.getSettingsValue(settings, 'view_product_page') != 0)) ? classes.cover : classes.coverContain, Utils.getSettingsValue(settings, 'darker_theme_products') == 1 ? classes.darkerProductImageCover : {})}
                                            image={prodThumbImgPath ? prodThumbImgPath : DEFAULT_PRODUCT_IMAGE}
                                            title={name}
                                            style={{
                                                backgroundSize: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'contain' : (prodThumbImgPath ? 'cover' : 'auto')),
                                                backgroundColor: (Utils.getSettingsValue(settings, 'isliquid_product_images_background_contain') == 1 && isLiquid == 1) ? 'contain' : (Utils.getSettingsValue(settings, 'product_images_background_contain') == 1 ? 'white' : 'initial'),
                                                'transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* W3C */
                                                '-webkit-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Safari & Chrome */
                                                '-moz-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Firefox */
                                                '-ms-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Internet Explorer */
                                                '-o-transform': 'scale(' + (parseInt(this.props.product.image_scale) / 100) + ')', /* Opera */
                                            }}
                                            onClick={() => {
                                                Utils.getSettingsValue(settings, 'view_product_page') != 0 ?
                                                    this.openProductPage(this.props.product)
                                                    :
                                                    (prodThumbImgPath ? this.setState({ photoOpened: true }) : void (0))
                                            }}
                                            data-class="each-product-image"
                                        />

                                        // onClick={() => Utils.getSettingsValue(settings, 'view_product_page') != 0 ? this.openProductPage(this.props.product) : void (0)}
                                    }

                                </Card>

                                {designVersion === 1 && designSubVersion === 2 ? addButton : null}
                                {designVersion === 1 && designSubVersion === 1 && description.length > 0 ? descriptionText : null}

                            </Grid>
                        }

                    </Grow >
                }
            </>
        );
    }
}

// TODO SET category name in state

// export default withStyles(styles)(ProductListItem);




const mapStateToProps = (state, ownProps) => {
    // const subcatId = ownProps.match.params.subcat_id;
    let chosenPlaceOrderTypes = [];
    let chosenPlaceId = state.places.chosen_place;
    let chosenPlace = state.places.places.filter(p => p.id == chosenPlaceId);
    if (chosenPlace.length == 0) {
        chosenPlace = null;
    } else {
        chosenPlace = chosenPlace[0];
        chosenPlaceOrderTypes = chosenPlace.ordertypes;
    }
    let viewProductPageId = state.products.view_product_page_id || 0;
    // console.log('statePpp', state)

    return {
        categories_hierarchy: state.categories.categories_hierarchy || {},
        // subcatId: subcatId,
        // subcategory: state.products.subcat_products && state.products.subcat_products ? state.products.subcat_products.filter(sub => sub && sub[0] && sub[0].id && sub[0].id == subcatId)[0] : [],
        // subcategory: state.products.subcat_products || null,
        // isLoading: state.products.isLoading || false,
        products: state.products || [],
        // categoriesLoaded: state.categories.categories_loaded || 0,
        settings: state.settings.settings || [],
        translations: state.lang,
        // currLang: state.lang.currLang
        chosenPlaceOrderTypes: chosenPlaceOrderTypes,
        view_product_page_id: viewProductPageId,
        clientSlug: state.client.slug || ""
    };
};

export default withStyles(styles)(connect(mapStateToProps, { setProductPageId, showHeaderCategory, setPromoProductForCategoryModalOpened, setScrollData, setAlertMessage })(ProductListItem));