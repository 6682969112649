import { GET_SETTINGS, SET_BACKGROUND_IMAGE } from '../actions/types';

const initialState = {
    settings: {
        // logo: {
        //     value: ""
        // },
        // logo_img_width: {
        //     value: 0
        // },
        // default_lang: {
        //     value: "bg"
        // },
        // translations_type: {
        //     value: 1
        // },
        // first_init_lang: {
        //     value: ""
        // },

        // default_lang: {
        //     value: "bg",
        // },
        // first_init_lang: {
        //     value: "",
        // },
        // translations_type: {
        //     value: "1",
        // },
        // change_language_position: {
        //     value: "header",
        // },
        // user_needs_position: {
        //     value: "header",
        // },
        // call_waiter_footer_button: {
        //     value: "1",
        // },
        // call_waiter_text: {
        //     value: "",
        // },
        // show_change_table: {
        //     value: "",
        // },
        // about_page_btn_position: {
        //     value: "header",
        // },
        // is_hotel: {
        //     value: "0",
        // },
        // about_page_title: {
        //     value: "",
        // },
        // default_currency_suffix: {
        //     value: "лв.",
        // },
        // subcategories_design: {
        //     value: "1",
        // },
        // reservations_url: {
        //     value: "",
        // },
        // reservations_position: {
        //     value: "header",
        // },
        // show_restaurant_location: {
        //     value: "0",
        // },
        // show_restaurant_location_position: {
        //     value: "header",
        // },
        // logo_opens_lang_modal: {
        //     value: "0",
        // },
        // search_btn_position: {
        //     value: "header",
        // },
        // second_call_btn: {
        //     value: "",
        // },
        // cart_icon: {
        //     value: "2",
        // },
        // show_liquid_on_non_liquid_view_product_page: {
        //     value: "0",
        // },
        // show_initial_banner_fast_order: {
        //     value: "0",
        // },
        // order_button_on_product_variant: {
        //     value: "2",
        // },
        // close_order_modal_after_order: {
        //     value: "0",
        // },
        // call_btn_instead_of_search: {
        //     value: "088 888 88 88",
        // },
        // user_needs_position: {
        //     value: "footer",
        // },
        // call_waiter_text: {
        //     value: "123",
        // },

        default_lang: {
            value: "bg",
        },
        first_init_lang: {
            value: "",
        },
        translations_type: {
            value: "1",
        },
        change_language_position: {
            value: "header",
        },
        default_currency_suffix: {
            value: "лв.",
        },
        menu_background_image: {
            value: "https://menu.tabl.bg/files/custom/tabl/gray-elegant-background-min.jpg",
        },
        logo: {
            value: "https://tabl.bg/img/tabl-transparent_black.png",
        },
        tabl_logo: {
            value: "https://tabl.bg/img/tabl-transparent_gray-small.png",
        },
        subcategories_design: {
            value: "1",
        },
        all_products_view_text: {
            value: "Поръчки, направени до 12:00 ще бъдат доставени на същия ден. <br/><br/> <b>Благодарим Ви, че пазарувате от нас ☺</b>",
        },
        place_address: {
            value: "България",
        },
        order_email_visible: {
            value: "0",
        },
        promocode_visible: {
            value: "0",
        },
        order_expire_time_hours: {
            value: "3",
        },
        clickable_product_variants: {
            value: "1",
        },
        variant_modal_with_comment: {
            value: "0",
        },
        promotions_text: {
            value: "",
        },
        promotions_html: {
            value: "",
        },
        promotions_with_navigation: {
            value: "0",
        },
        promotions_title: {
            value: "ПРОМОЦИИ",
        },
        reservations_url: {
            value: "",
        },
        reservations_position: {
            value: "header",
        },
        show_restaurant_location: {
            value: "0",
        },
        show_restaurant_location_position: {
            value: "header",
        },
        logo_opens_lang_modal: {
            value: "0",
        },
        call_btn_instead_of_search: {
            value: "",
        },
        search_btn_position: {
            value: "header",
        },
        second_call_btn: {
            value: "",
        },
        cart_icon: {
            value: "2",
        },
        meta_title_place_name: {
            // value: "TabL",
            value: "",
        },
        meta_description: {
            value: "Дигитално меню TabL",
        },
        og_image_link: {
            value: "https://tabl.bg/img/tablbg-og.png",
        },
        about_page_btn_position: {
            value: "header",
        },
        about_page_title: {
            value: "",
        },
        about_page_working_time: {
            value: "<p><b>Понеделник - Неделя</b></p>\n\n<p><b>09:00 - 03:00</b></p>\n",
        },
        about_page_delivery_prices: {
            value: "<b>Всеки ден от 11 до 23 часа</b>",
        },
        about_page_payment_types: {
            value: "<p>\r\n<b>В Брой</b>\r\n<br />\n<br />\n<b>С Карта - само в обекта</b>\r\n</p>\n",
        },
        hide_product_images_without_img: {
            value: "1",
        },
        clickable_product_variants_only_for_products_without_image: {
            value: "1",
        },
        google_analytics_code: {
            value: "<style>\r\n\r\n</style>",
        },
        white_background: {
            value: "0",
        },
        show_variant_additional_price: {
            value: "0",
        },
        view_product_page: {
            value: "1",
        },
        admin_notify_on_new_order: {
            value: "1",
        },
        user_needs_position: {
            value: "header",
        },
        show_liquid_on_non_liquid_view_product_page: {
            value: "0",
        },
        show_initial_banner_fast_order: {
            value: "0",
        },
        order_button_on_product_variant: {
            value: "2",
        },
        close_order_modal_after_order: {
            value: "0",
        },
        call_waiter_footer_button: {
            value: "1",
        },
        call_waiter_and_bill_footer_button: {
            value: "1",
        },
        product_images_background_contain: {
            value: "0",
        },
        isliquid_product_images_background_contain: {
            value: "1",
        },
        seccess_message_delivery_order: {
            value: "Вашата поръчка е приета",
        },
        seccess_message_pickup_order: {
            value: "Вашата поръчка е приета",
        },
        required_delivery_pickup_times: {
            value: "0",
        },
        show_initial_html_banner: {
            value: "0",
        },
        disable_hide_header_logo: {
            value: "0",
        },
        sms_phone_after_order: {
            value: "",
        },
        about_restaurant_page_title_in_left: {
            value: "0",
        },
        new_order_email_address: {
            value: "",
        },
        disable_overtime_orders: {
            value: "1",
        },
        allow_auto_print_orders: {
            value: "0",
        },
        is_hotel: {
            value: "0",
        },
        menu_header_background: {
            value: "",
        },
        menu_header_icons_color: {
            value: "",
        },
        menu_call_button_background: {
            value: "",
        },
        menu_call_button_color: {
            value: "",
        },
        menu_top_left_dialpad_button_background: {
            value: "",
        },
        menu_top_left_dialpad_button_color: {
            value: "",
        },
        menu_product_addtocart_button_background: {
            value: "",
        },
        menu_product_addtocart_button_color: {
            value: "",
        },
        menu_product_viewpage_addtocart_button_background: {
            value: "",
        },
        menu_product_viewpage_addtocart_button_color: {
            value: "",
        },
        menu_dialogs_background: {
            value: "",
        },
        menu_dialogs_colors: {
            value: "",
        },
        menu_underline_colors: {
            value: "",
        },
        product_theme: {
            value: "",
        },
        show_delivery_price_not_included_text: {
            value: "0",
        },
        visible_about_page_delivery_prices_text_in_cart: {
            value: "0",
        },
        bigger_logo: {
            value: "",
        },
        order_print_logo_url: {
            value: "",
        },
        order_print_menu_url: {
            value: "",
        },
        order_print_qr_code_url: {
            value: "",
        },
        split_header_category_subcategory_on_two_rows: {
            value: "0",
        },
        hide_products_for_ordering_with_token: {
            value: "",
        },
        ask_for_bill_hidden: {
            value: "",
        },
        hide_order_delivery_pickup_date: {
            value: "",
        },
        validate_phone_number_regex: {
            value: "",
        },
        disable_click_on_navigate_me_button_in_pickup_modal: {
            value: "0",
        },
        show_change_table: {
            value: "",
        },
        pdf_menu_enabled: {
            value: "0",
        },
        minimal_order_price: {
            value: "0",
        },
        enable_lunch_menu_share: {
            value: "",
        },
        lunch_menu_footer_text: {
            value: "",
        },
        preorder_modal_info_text: {
            value: "",
        },
        preorder_modal_info_text_position: {
            value: "header",
        },
        delivery_modal_info_text_position: {
            value: "header",
        },
        delivery_modal_info_text: {
            value: "",
        },
        remove_background_color_image_categories: {
            value: "0",
        },
        new_spot_order_email_address: {
            value: "",
        },
        sms_phone_after_spot_order: {
            value: "",
        },
        add_to_cart_view_page_button_position: {
            value: "top",
        },
        required_ingredients_title: {
            value: "",
        },
        addable_ingredients_title: {
            value: "",
        },
        removeable_ingredients_title: {
            value: "",
        },
        show_change_ingredients_button: {
            value: "0",
        },
        show_footer: {
            value: "0",
        },
        facebook_page: {
            value: "",
        },
        instagram_page: {
            value: "",
        },
        youtube_page: {
            value: "",
        },
        tripadvisor_page: {
            value: "",
        },
        footer_html_content: {
            value: "",
        },
        about_page_logo: {
            value: "",
        },
        show_cutlery_option_on_making_preorder_order: {
            value: "",
        },
        show_cutlery_option_on_making_delivery_order: {
            value: "",
        },
        show_cutlery_option_on_making_room_order: {
            value: "",
        },
        hide_order_room_date: {
            value: "",
        },
        about_page_facilities: {
            value: "",
        },
        about_page_household: {
            value: "",
        },
        about_page_technical_support: {
            value: "",
        },
        about_page_description: {
            value: "",
        },
        raspberry_used: {
            value: "",
        },
        raspberry_anydesk_id: {
            value: "",
        },
        call_waiter_text: {
            value: "",
        },
        options_footer_text: {
            value: "",
        },
        raspberry_display: {
            value: "",
        },
        keep_images_transparent: {
            value: "",
        },
        show_initial_html_banner_editor: {
            value: "",
        },
        initial_html_banner_button_action: {
            value: "close",
        },
        show_change_place_footer: {
            value: "",
        },
        label_call_btn_instead_of_search: {
            value: "",
        },
        label_second_call_btn: {
            value: "",
        },
        booking_interval: {
            value: "15",
        },
        about_page_title_link: {
            value: "",
        },
        call_waiter_and_call_button: {
            value: "",
        },
        new_user_need_email_address: {
            value: "",
        },
        new_room_order_email_address: {
            value: "",
        },
        orders_allowed: {
            value: "1",
        },
        orders_may_be_late: {
            value: "",
        },
        show_is_new_client_by_phone: {
            value: "0",
        },
        orders_may_be_late_text: {
            value: "",
        },
        orders_not_allowed_text: {
            value: "",
        },
        hide_restaurant_orders_button_in_order_modal: {
            value: "0",
        }

    },
    mainBackground: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.payload
            }
        case SET_BACKGROUND_IMAGE:
            return {
                ...state,
                mainBackground: action.payload
            }
        default:
            return state;
    }
}