import { SWITCH_LANGUAGE, LIST_LANGUAGES } from './../actions/types';
import { translations } from '../translations.js';
import Auth from '../../utils/Auth';

const initialState = {
    currLang: Auth.getCurrLang() ?? 'bg',
    languages: [],
    translations: translations[Auth.getCurrLang() ?? 'bg']
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SWITCH_LANGUAGE:
            return {
                ...state,
                currLang: action.payload,
                translations: translations[action.payload] ? translations[action.payload] : translations["bg"]
            }
        case LIST_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
            }
        default:
            return state
    }
}