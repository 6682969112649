import React from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DirectionsIcon from '@material-ui/icons/Directions';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui//core/Grow';
import clsx from 'clsx';
import GeneralPromotions from './GeneralPromotions';
import Auth from '../../utils/Auth';
import Utils from '../../utils/Utils';

const useStyles = makeStyles(theme => ({
    promotionContainer: {
        width: '97%',
        maxWidth: 700,
        margin: '0 auto',
        marginTop: 13,
        position: 'relative',
        // overflow: 'hidden',
        height: 'auto',
        background: 'rgba(255, 255, 255, 0.85)',
        'border-bottom-right-radius': 7,
        'border-bottom-left-radius': 7,
        'border-top-right-radius': 7,
        'border-top-left-radius': 7,
        // 'box-shadow': '0px -1px 2px #ccc',
        // border: '1px solid #ff7429',
    },
    promotionContainerImageSubcat: {
        width: '100% !important',
        maxWidth: 'none !important',
        borderRadius: '0 !important',
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
    },

    promotionTitleHorizontal: {
        // background: '#ff7429',
        // background: 'linear-gradient(90deg, rgb(254,105,2) 0%, rgb(203,65,2) 78%)',
        background: 'rgb(255, 113, 47)',
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        'border-top-right-radius': 7,
        'border-top-left-radius': 7,
        // 'border-bottom-right-radius': 7,
        // 'border-bottom-left-radius': 7,
    },

    promotionTitle: {
        letterSpacing: -1,
        padding: '1px 8px',
        textAlign: 'center',
        color: 'white',
        'border-top-left-radius': 7,
        'border-bottom-left-radius': 7,
        fontSize: 12,
        zIndex: 12,
    },

    promotionContentCentered: {
        textAlign: 'center',
    },

    promotionItem: {
        fontSize: 14,
        '@media screen and (max-width: 350px)': {
            fontSize: 13,
        },
        padding: '6px 0',
        width: 'calc(100% - 40px)'
    },

    promotionIcon: {
        color: '#fd6a00',
    },


    // Promotions with navigation
    promotionsWithNavigationContainer: {
        borderRadius: 6,
        backgroundColor: '#e6e6e6',
        // display: 'grid',
        // 'grid-template-columns': 'calc(100% - 100px) 100px',
        display: 'flex',
        'justify-content': 'center',
    },
    promotionsNavigationLeftSide: {
        placeSelf: 'center',
        '@media screen and (max-width: 350px)': {
            fontSize: '16px !important'
        },
        '& *': {
            '@media screen and (max-width: 350px)': {
                fontSize: '16px !important'
            },
        }
    },
    promotionsNavigationRightSide: {
        // width: '100%',
        placeSelf: 'center',
        verticalAlign: 'bottom',
        // borderLeft: '1px solid #ccc',
        textAlign: 'center',
    },
    promotionsNavigationLink: {
        display: 'grid',
        'grid-template-rows': '50% 50%',
        textDecoration: 'none',
        padding: '0 10px',
        '@media screen and (max-width: 350px)': {
            padding: 0,
        }
    },
    promotionsNavigationIcon: {
        fontSize: 30,
        color: '#0064ac',
    },
    promotionsNavigationText: {
        textAlign: 'center',
        fontSize: 14,
        color: '#0064ac',
        paddingTop: 5,
    },

    promotionRightSideRowCats: {
        marginBottom: 50,
        '@media screen and (min-width: 1000px)': {
            gridColumn: 2,
            width: '100%',
            position: 'sticky',
            top: '0',
            height: 'fit-content',
        }
    }
}));

const getPromotionTextByNumber = (num) => {
    if (num == 1) {
        return '-ва';
    } else if (num == 2) {
        return '-ра';
    }
    return '-та';
}



const Promotions = (props) => {
    const classes = useStyles();
    const { settings, translations, registeredUserDiscount } = props;

    // console.log(`registeredUserDiscount`, registeredUserDiscount);
    // console.log(`Auth.getDecodedUser()`, Auth.getDecodedUser());

    let promotions = [];
    let promotionsLength = 0;
    if (settings.promotions_html && settings.promotions_html.value) {
        const promotions_with_navigation = settings.promotions_with_navigation && settings.promotions_with_navigation.value && settings.promotions_with_navigation.value == '1';

        promotions =
            <div className={promotions_with_navigation ? classes.promotionsWithNavigationContainer : {}}>
                <div className={classes.promotionsNavigationLeftSide}
                    dangerouslySetInnerHTML={{
                        __html: settings.promotions_html.value
                    }}>
                </div>
                {promotions_with_navigation ?
                    <div className={classes.promotionsNavigationRightSide}>
                        <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/dir/?api=1&destination=' + (settings.place_address ? settings.place_address.value : '')} className={classes.promotionsNavigationLink}>
                            <IconButton aria-label="navigate" color="primary" >
                                <DirectionsIcon className={classes.promotionsNavigationIcon} />
                            </IconButton>
                            <div className={classes.promotionsNavigationText}>{translations && translations.common && translations.common.navigate_me ? translations.common.navigate_me : ''}</div>
                        </a>
                    </div>
                    :
                    null
                }
            </div>

        promotionsLength = 2;
    } else if (settings.promotions_text && settings.promotions_text.value) {
        promotions = JSON.parse(settings.promotions_text.value);
        promotions = Object.values(promotions).map((p, k) =>
            <ListItem button className={classes.promotionItem} key={k}>
                <ArrowRightIcon className={classes.promotionIcon} /> {p}
            </ListItem>
        )
        promotionsLength = Object.keys(promotions).length;
    }

    const subcategories_design = settings.subcategories_design && settings.subcategories_design.value ? settings.subcategories_design.value : false;
    let ghbPlacesIds = [97, 98, 99, 100, 102];

    // console.log(`subcategories_design`, subcategories_design)

    // let promotionContainer = null;
    // if (promotionsLength > 0) {
    //     promotionContainer = (
    //         <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
    //             <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
    //                 <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
    //                     <span>
    //                         {settings.promotions_title && settings.promotions_title.value ? settings.promotions_title.value : ''}
    //                     </span>
    //                 </div>
    //                 <div className={classes.promotionContent}>
    //                     {promotions}
    //                 </div>
    //             </div>
    //         </Grow>
    //     )
    // }

    // console.log(`registeredUserDiscount`, registeredUserDiscount);
    // console.log(`registeredUserDiscount.discount`, registeredUserDiscount.discount);

    return (
        <div className={subcategories_design == '1' ? {} : classes.promotionRightSideRowCats} data-class="promotions-container" >
            {promotionsLength > 0 ?
                <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                    <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                        <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                            <span>
                                {settings.promotions_title && settings.promotions_title.value ? settings.promotions_title.value : ''}
                            </span>
                        </div>
                        <div className={classes.promotionContent}>
                            {promotions}
                        </div>
                    </div>
                </Grow>
                :
                null
            }

            {/* Registered user discount */}
            {registeredUserDiscount && registeredUserDiscount.discount && Object.values(registeredUserDiscount.discount).length > 0 ?
                <React.Fragment>
                    {subcategories_design == '2' ?
                        <React.Fragment>
                            {/* User logged */}
                            {Auth.getDecodedUser() ?
                                <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                                    <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                                        <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                                            <span>
                                                Отстъпка
                                            </span>
                                        </div>

                                        {parseInt(registeredUserDiscount.discount.on_order_number) == (parseInt(registeredUserDiscount.ordersCount) + 1) ?
                                            < div className={classes.promotionContent}>
                                                {/* Логнат, на който тази поръчка е с отстъпка */}
                                                <ListItem button className={classes.promotionItem}>
                                                    <p style={{ paddingLeft: 10 }}><b>Поръчай сега и се възползвай от {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка</b></p>
                                                </ListItem>
                                                <ListItem button className={classes.promotionItem} >
                                                    <ArrowRightIcon className={classes.promotionIcon} /> Ние ценим лоялните си клиенти, затова при тази твоя поръчка (при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)}) ти получаваш {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка от сметката.
                                                </ListItem>
                                            </div>
                                            :
                                            < div className={classes.promotionContent}>
                                                {/* Логнат, който има нужда от още 3 поръчки (само 1 път е поръчвал) */}
                                                <ListItem button className={classes.promotionItem}>
                                                    <p style={{ paddingLeft: 10 }}><b>Вземете {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} поръчка.</b></p>
                                                </ListItem>
                                                <ListItem button className={classes.promotionItem} >
                                                    <ArrowRightIcon className={classes.promotionIcon} /> Направи
                                                    {registeredUserDiscount.ordersCount == null ?
                                                        <> {parseInt(registeredUserDiscount.discount.on_order_number) - parseInt(1)}</>
                                                        :
                                                        <> още {parseInt(registeredUserDiscount.discount.on_order_number) - parseInt(registeredUserDiscount.ordersCount || 0) - 1}</>
                                                    }
                                                    &nbsp;{(parseInt(registeredUserDiscount.discount.on_order_number) - parseInt(registeredUserDiscount.ordersCount || 0) - 1) == 1 ? 'поръчка' : 'поръчки'}  и се възползвай от {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка от следващата поръчка.
                                                </ListItem>
                                                <ListItem button className={classes.promotionItem} >
                                                    <ArrowRightIcon className={classes.promotionIcon} />  Промоцията е валидна за всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} твоя поръчка през сайта.
                                                </ListItem>
                                            </div>
                                        }

                                    </div>
                                </Grow>
                                :
                                <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                                    <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                                        <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                                            <span>
                                                Отстъпка
                                            </span>
                                        </div>
                                        <div className={classes.promotionContent}>
                                            <ListItem button className={classes.promotionItem}>
                                                <p style={{ paddingLeft: 10 }}><b>Вземете {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} поръчка.</b></p>
                                            </ListItem>
                                            <ListItem button className={classes.promotionItem} >
                                                <ArrowRightIcon className={classes.promotionIcon} /> Регистрирайте се или влезте в своя профил
                                            </ListItem>
                                            <ListItem button className={classes.promotionItem} >
                                                <ArrowRightIcon className={classes.promotionIcon} />
                                                Ние ценим лоялните си клиенти, затова при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} поръчка ти получаваш {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка от сметката.
                                            </ListItem>
                                            <ListItem button className={classes.promotionItem} >
                                                <ArrowRightIcon className={classes.promotionIcon} />
                                                Промоцията е валидна само за поръчки през сайта.
                                            </ListItem>
                                        </div>
                                    </div>
                                </Grow>
                            }
                        </React.Fragment>
                        :
                        null
                    }
                </React.Fragment>
                :
                null
            }


            {/* Registered user discound */}
            {registeredUserDiscount && registeredUserDiscount.discount && Object.values(registeredUserDiscount.discount).length > 0 ?
                <React.Fragment>
                    {subcategories_design == '1' ?
                        <React.Fragment>
                            {Auth.getDecodedUser() ?
                                <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                                    <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                                        <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                                            <span>
                                                Отстъпка
                                            </span>
                                        </div>
                                        <div className={classes.promotionContent}>
                                            <div >
                                                <div className={classes.promotionsNavigationLeftSide}>

                                                    {parseInt(registeredUserDiscount.discount.on_order_number) == (parseInt(registeredUserDiscount.ordersCount) + 1) ?
                                                        <div style={{ textAlign: 'center', padding: 10 }}>
                                                            <div
                                                                style={{ fontSize: 18 }}
                                                                className="skiptranslate"
                                                            >
                                                                <b>Поръчай сега и се възползвай от {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка</b>
                                                            </div>
                                                            <div
                                                                style={{ fontSize: 16, paddingTop: 5 }}
                                                            >
                                                                <li>Ние ценим лоялните си клиенти, затова при тази твоя поръчка (при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)}) ти получаваш {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка от сметката.</li>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ textAlign: 'center', padding: 10 }}>
                                                            <div
                                                                style={{ fontSize: 18 }}
                                                                className="skiptranslate"
                                                            >
                                                                <b>Вземете {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} поръчка.</b>
                                                            </div>
                                                            <div
                                                                style={{ fontSize: 16, paddingTop: 5 }}
                                                            >
                                                                <li>Направи
                                                                    {registeredUserDiscount.ordersCount == null ?
                                                                        <> {parseInt(registeredUserDiscount.discount.on_order_number) - parseInt(1)}</>
                                                                        :
                                                                        <> още {parseInt(registeredUserDiscount.discount.on_order_number) - parseInt(registeredUserDiscount.ordersCount || 0) - 1}</>
                                                                    }
                                                                    &nbsp;{(parseInt(registeredUserDiscount.discount.on_order_number) - parseInt(registeredUserDiscount.ordersCount || 0) - 1) == 1 ? 'поръчка' : 'поръчки'} и се възползвай от {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка от следващата поръчка.
                                                                </li>
                                                                <li>Промоцията е валидна за всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} твоя поръчка през сайта.</li>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grow>
                                :
                                <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                                    <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                                        <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                                            <span>
                                                {/* {settings.promotions_title && settings.promotions_title.value ? settings.promotions_title.value : ''} */}
                                                Отстъпка
                                            </span>
                                        </div>
                                        <div className={classes.promotionContent}>
                                            <div >
                                                <div className={classes.promotionsNavigationLeftSide}>

                                                    <div style={{ textAlign: 'center', padding: 10 }}>
                                                        <div
                                                            style={{ fontSize: 18 }}
                                                            className="skiptranslate"
                                                        >
                                                            <b>Вземете {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} поръчка.</b>
                                                        </div>
                                                        <div
                                                            style={{ fontSize: 16, paddingTop: 5 }}
                                                        >
                                                            <li>Регистрирайте се или влезте в своя профил</li>
                                                            <li>Ние ценим лоялните си клиенти, затова при всяка {registeredUserDiscount.discount.on_order_number}{getPromotionTextByNumber(registeredUserDiscount.discount.on_order_number)} поръчка ти получаваш {registeredUserDiscount.discount.discount_amount} {registeredUserDiscount.discount.discount_type == "money" ? 'лв.' : '%'} отстъпка от сметката.</li>
                                                            <li>Промоцията е валидна само за поръчки през сайта.</li>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grow>
                            }
                        </React.Fragment>
                        :
                        null
                    }
                </React.Fragment>
                :
                null
            }

            {Utils.getSettingsValue(settings, 'show_order_times_information_in_menu_as_promotion') == 1 ?
                <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                    <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                        <div className={(subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))}
                            style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                            <span>
                                {/* {settings.promotions_title && settings.promotions_title.value ? settings.promotions_title.value : ''} */}
                                Поръчай
                            </span>
                        </div>
                        <div className={classes.promotionContent}>
                            <div >
                                <div className={classes.promotionsNavigationLeftSide}>

                                    <div style={{ padding: 10, paddingRight: 50 }}>
                                        <div
                                            style={{ fontSize: 16, paddingTop: 5 }}
                                        >
                                            <li>При поръчки за вкъщи, времето за доставка е до {Utils.getSettingsValue(settings, 'default_time_until_order_ready_delivery')} мин</li>
                                            <li>При поръчка за взимане от място, времето докато ще бъде готова е до {Utils.getSettingsValue(settings, 'default_time_until_order_ready_pickup')} мин</li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grow>
                :
                null
            }


            {
                (settings.orders_may_be_late_text && settings.orders_may_be_late_text.value && settings.orders_may_be_late_text.value != '' && Utils.getSettingsValue(settings, 'orders_may_be_late') == 1) ?
                    <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                        <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                            <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                                <span>
                                    Info
                                </span>
                            </div>
                            <div className={classes.promotionContentCentered}>

                                <div className={classes.promotionsWithNavigationContainer} style={{ padding: '0 10px 0 10px' }}>
                                    <div dangerouslySetInnerHTML={{
                                        __html: settings.orders_may_be_late_text.value
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grow>
                    :
                    null
            }


            {
                (settings.orders_not_allowed_text && settings.orders_not_allowed_text.value && settings.orders_not_allowed_text.value != '' && Utils.getSettingsValue(settings, 'orders_allowed') == 0) ?
                    <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                        <div className={subcategories_design == '1' ? clsx(classes.promotionContainer, 'box', classes.promotionContainerImageSubcat) : clsx(classes.promotionContainer, 'box')}>
                            <div className={promotionsLength == 1 ? classes.promotionTitleHorizontal : (subcategories_design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} style={settings.promotions_title && settings.promotions_title.value ? {} : { display: 'none' }}>
                                <span>
                                    Info
                                </span>
                            </div>
                            <div className={classes.promotionContentCentered}>

                                <div className={classes.promotionsWithNavigationContainer} style={{ padding: '0 10px 0 10px' }}>
                                    <div dangerouslySetInnerHTML={{
                                        __html: settings.orders_not_allowed_text.value
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grow>
                    :
                    null
            }

            {
                !Auth.isTabletMode() && !ghbPlacesIds.includes(parseInt(Auth.getOpenedPlace())) ?
                    <GeneralPromotions design={subcategories_design} />
                    :
                    null
            }

            {settings.html_promotion && settings.html_promotion.value && settings.html_promotion.value != '' ?
                <div data-class="html_promotion"
                    dangerouslySetInnerHTML={{
                        __html: settings.html_promotion.value
                    }}>
                </div>
                :
                null
            }

        </div >
    )

}


const mapStateToProps = (state, ownProps) => {
    return {
        registeredUserDiscount: state.registeredUserDiscount.registered_user_discounts || []
    };
}

export default (connect(mapStateToProps, {})(Promotions));
