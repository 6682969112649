import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow } from '@material-ui/core';
import clsx from 'clsx';
import history from './../../utils/history';

import Auth from '../../utils/Auth';

const styles = theme => ({
    modal: {
        '& .MuiPaper-root': {
            width: '350px !important',
        }
    },
    modalTitle: {
        textAlign: 'center',
        backgroundColor: "rgb(203,65,2)",
        color: 'white',
        boxShadow: '0 1px 5px 0px black',
        '& .MuiTypography-root': {
            fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
        }
    },
    dialogContent: {
        'background-position': 'center bottom',
        'background-size': 'cover',
    },
    dialogContentText: {
        paddingTop: 10,
    },
    modalTypesBtns: {
        float: 'right'
    },
    generalPromo: {
        // backgroundImage: 'url(https://scontent.fsof8-1.fna.fbcdn.net/v/t1.15752-9/s2048x2048/161893428_474745046977048_5352671020103990612_n.jpg?_nc_cat=104&ccb=1-3&_nc_sid=ae9488&_nc_ohc=D2rqnGMLz5QAX_WMuxG&_nc_oc=AQl6GLPjS1Ylngyisv7sxg_rYFZX6eUIBTV1LnFfG3b5HirkJYHY0oeylS2uDHwXwp0&_nc_ht=scontent.fsof8-1.fna&tp=7&oh=5fc6df0f0ebed0b6740dbd87f28e1b43&oe=60CA7543)',
        backgroundImage: 'linear-gradient( rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0.35) ), url(https://tabl.bg/img/website_images/sea-min.jpg)',

        backgroundPosition: 'left',
        backgroundAttachment: 'initial',
        backgroundSize: 'cover',
        position: 'relative',
        margin: '0 auto',
        padding: 25,
        background: 'rgba(255, 255, 255, 0.65)',
        textAlign: 'center',
        cursor: 'pointer',
    },
    promoImageCat: {
    },
    promoRowCat: {
        maxWidth: 700,
        borderRadius: 7,
        marginTop: 13,
    },
    promotionTitle: {
        letterSpacing: -1,
        padding: '1px 8px',
        textAlign: 'center',
        color: 'white',
        'border-top-left-radius': 7,
        'border-bottom-left-radius': 7,
        fontSize: 12,
        zIndex: 12,
    },
});

class GeneralPromotions extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {
        //   const { classes, fullWidth = true, maxWidth = 'xs', open, handleClose, translations } = this.props;
        const { classes } = this.props;

        //   console.log(this.props.generalPromotions)
        return (
            <Grow in={true} timeout={{ enter: 450, exit: 450 }}>
                <div>
                    {Object.values(this.props.generalPromotions).map(p => {
                        return <div className={this.props.design == '1' ? clsx(classes.promoImageCat, classes.generalPromo) : clsx(classes.promoRowCat, classes.generalPromo)} onClick={() => window.open(p.link, '_blank')}>
                            <div className={(this.props.design == '1' ? clsx(classes.promotionTitle, 'ribbon', 'ribbon-image-subcat') : clsx(classes.promotionTitle, 'ribbon'))} >
                                <span>
                                    TabL инфо
                                </span>
                            </div>

                            {
                                p.active == 1 ?
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: p.content
                                        }}>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    })
                    }
                </div >
            </Grow>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        generalPromotions: state.generalPromotions.general_promotions || []

    };
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, {
    addErrorMessages, addSuccessMessage
})(GeneralPromotions)));
