// import axios from 'axios';
import { SWITCH_LANGUAGE } from './types';
import axios from 'axios';
import { LIST_LANGUAGES } from './types';
import { API_URL } from '../../constants';
import Auth from '../../utils/Auth';

export const changeLanguage = (language, translationType = null) => dispatch => {

    //set lang cookie only if normal translations are used
    if(translationType && translationType == 2) {
        Auth.setCurrLang(language);
    }

    dispatch({
        type: SWITCH_LANGUAGE,
        payload: language
    })
}

export const listLanguages = () => dispatch => {
    axios
        .get(API_URL + 'languages')
        .then(res =>
            dispatch({
                type: LIST_LANGUAGES,
                payload: res.data
            })
        ).catch(error => {
            throw(error);
        });
}